import countUp from "@/views/my-components/widget/countUp.vue";
export default {
  name: "card2",
  components: {
    countUp
  },
  props: {
    id: String,
    backgroundColor: String,
    backgroundImage: String,
    title: String,
    prefix: String,
    suffix: String,
    endVal: Number,
    color: {
      type: String,
      default: "#19be6b"
    },
    dataColor: {
      type: String,
      default: "#3f4255"
    },
    countSize: {
      type: String,
      default: "24px"
    },
    countWeight: {
      type: Number,
      default: 600
    },
    description: String,
    strokeWidth: {
      type: Number,
      default: 6.5
    },
    descriptionColor: {
      type: String,
      default: "#b5b5c5"
    },
    descriptionSize: {
      type: String,
      default: "12px"
    },
    descriptionWeight: {
      type: Number,
      default: 500
    }
  }
};